<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Usuario (*):</label>
              <select2 id="usu_id" v-model="item.usu_id" :options="usuarios" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Dependencia (*):</label>
              <select2 id="dep_id" v-model="item.dep_id" :options="dependencias" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="col-md-4 col-form-label">Serie (*):</label>
              <select2 id="ser_id" v-model="item.ser_id" :options="series" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="col-md-4 col-form-label">Clase de documento (*):</label>
              <select2 id="tcr_id" v-model="item.tcr_id" :options="tpocors" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Ver (*):</label>
              <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_ver">
            </div>
            <div class="form-group col-md-3">
              <label>Modificar (*):</label>
              <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_mdfcr">
            </div>
            <div class="form-group col-md-3">
              <label>Imprimir (*):</label>
              <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_imprmr">
            </div>
            <div class="form-group col-md-3">
              <label>Modificar otros usuarios (*):</label>
              <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_mdfcr_otros">
            </div>
            <div class="form-group col-md-3">
              <label>Archivar (*):</label>
              <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_archvr">
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import Select2 from '../select2/Select2.vue';

  export default {
    components: {
      Select2
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        usuarios: [],
        dependencias: [],
        series: [],
        tpocors: [],
        search: {}
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.search = this.$route.params.search;
      this.fetchUsuario();
      this.fetchDependencia();
      this.fetchSerie();
      this.fetchTpocor();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear Usuario por dependencia y serie';
        this.buttontext = 'Agregar';
      } else {
        this.title = 'Editar Usuario por dependencia y serie';
        this.buttontext = 'Actualizar';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchUsuario(){
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
            var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
            var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.dependencias = list.map(i => ({ id: String(i._id), text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' }));
        });
      },
      fetchSerie()
      {
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
            var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.series = list.map(i => ({ id: String(i._id), text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' }));
        });
      },
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
            var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
        });
      },
      saveItem(){
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/uxr/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            this.$router.replace({ name: 'DisplayUsuxrad', params: { search: this.search } });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/uxr/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayUsuxrad', params: { search: this.search } });
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/uxr/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      },
      cancel(){
        this.$router.replace({ name: 'DisplayUsuxrad', params: { search: this.search } });
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
